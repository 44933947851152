var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import MainDetailIcons from "./MainDetailIcons.vue";
import SmallPhotoSwiper from "./SmallPhotoSwiper.vue";
import queryString from "query-string";
import uniq from "lodash/uniq";
import getFormattedRentalPerWeek from "../../../features/accommodation/getFormattedRentalPerWeek";
import propertyListingPriceFragment from "../../../features/accommodation/propertyListingPriceFragment.graphql";
import furnishIcon from "../../../images/furnish.svg";
export default Vue.extend({
    components: {
        MainDetailIcons: MainDetailIcons,
        SmallPhotoSwiper: SmallPhotoSwiper,
    },
    fragments: {
        property: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment PropertyCard_property on PropertyListing {\n        id\n        slug\n        propertyName\n        fullAddress\n        isFurnished\n        highlightText\n        photos {\n          id\n          smallUrl\n        }\n        roomTypes {\n          propertyTypeName\n        }\n        ...PropertyListingPrice_propertyListing\n        ...MainDetailIcons_property\n      }\n      ", "\n      ", "\n    "], ["\n      fragment PropertyCard_property on PropertyListing {\n        id\n        slug\n        propertyName\n        fullAddress\n        isFurnished\n        highlightText\n        photos {\n          id\n          smallUrl\n        }\n        roomTypes {\n          propertyTypeName\n        }\n        ...PropertyListingPrice_propertyListing\n        ...MainDetailIcons_property\n      }\n      ", "\n      ", "\n    "])), propertyListingPriceFragment, MainDetailIcons.options.fragments.property),
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
        linkQuery: {
            type: Object,
            default: undefined,
        },
    },
    data: function () {
        return {
            furnishIcon: furnishIcon,
        };
    },
    computed: {
        formattedRental: function () {
            return getFormattedRentalPerWeek(this.$t.bind(this), this.property);
        },
        roomTypeNames: function () {
            return uniq(this.property.roomTypes.map(function (roomType) { return roomType.propertyTypeName; }));
        },
        link: function () {
            var link = "/accommodation/listing/".concat(this.property.slug);
            if (!this.linkQuery || Object.keys(this.linkQuery).length === 0) {
                return link;
            }
            return "".concat(link, "?").concat(queryString.stringify(this.linkQuery));
        },
    },
});
var templateObject_1;
