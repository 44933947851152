import Vue from "vue";
export default Vue.extend({
    props: {
        photos: {
            type: Array,
            required: true,
        },
        interactionEnabled: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            swiperOptions: {
                loop: true,
                slidesPerView: 1,
                slidesPerColumn: 1,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
        };
    },
});
